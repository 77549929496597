import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import logo from '../../assets/img/logo-driver.png';
import '../../assets/css/forget.css';
import GridContainer from '../../components/Grid/GridContainer';
import { PhoneMaskCustom } from '../../components/CustomMasks/Masks';

import GridItem from '../../components/Grid/GridItem';
import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

class Forget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: '',
      email: '',
      errTxt: '',
      errPhone: false
    };
  }

  validatePhone = phone => {
    const regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
    return regex.test(phone);
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handlePhone = event => {
    const phone = event.target.value.trim();
    this.setState({
      phone,
      errPhone: !this.validatePhone(phone)
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { email } = this.state;
    let { phone } = this.state;
    phone = phone.trim();

    axios({
      method: 'post',
      url: 'https://hapi.beelog.app/account/recovery-password',
      data: {
        phone,
        email
      }
    })
      .then(() => {
        this.setState({ send: true });
      })
      .catch(err => {
        if (err.response.status) this.setState({ errTxt: 'Dados inválidos' });
      });
  };

  render() {
    const { classes } = this.props;
    const { send, errTxt, email, phone, errPhone } = this.state;

    if (send || errTxt) {
      return (
        <GridContainer className="App" justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card className={classes.cardSignup}>
              <h3 style={{ color: 'black' }}>
                {errTxt || `Uma senha nova foi enviada para ${email}`}
              </h3>
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  this.setState({
                    errTxt: '',
                    send: false
                  });
                }}
              >
                {errTxt ? 'Voltar' : 'Ok'}
              </Button>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }

    return (
      <GridContainer className="App" justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card className={classes.cardSignup}>
            <div style={{ display: 'inline-block', width: 250 }}>
              <CardActionArea>
                <CardMedia className={classes.media}>
                  <img src={logo} className="App-logo" alt="logo" />
                </CardMedia>
                <CardContent>
                  <Typography component="p">
                    Preencha os campos abaixo para receber uma nova senha.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  required
                  id="email-input"
                  label="Email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={this.handleChange}
                />
                <TextField
                  required
                  error={errPhone}
                  id="phone-input"
                  label="Telefone"
                  type="text"
                  name="phone"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: PhoneMaskCustom
                  }}
                  value={phone}
                  onChange={this.handlePhone}
                />
                <CardActions>
                  <Button disabled={errPhone} type="submit" size="small" color="primary">
                    Enviar
                  </Button>
                </CardActions>
              </form>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Forget.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(Forget);
